import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import AudioRecorder, { InnerAudioRecorder } from './audio-recorder';

interface AudioRecorderWidgetProps {
    id: string;
    value: string;
    required: boolean;
    disabled: boolean;
    readonly: boolean;
    label: string;
    onChange: (value: string) => void;
}

const AudioRecorderWidget = ({
    id,
    required,
    disabled,
    readonly,
    label,
    value,
    onChange,
}: AudioRecorderWidgetProps) => {
    return (
        <FormControl id={id}>
            <FormLabel htmlFor={id}>
                {label}
                {required && <Text as="span" color='red.500' ms='1'>*</Text>}
            </FormLabel>
            <InnerAudioRecorder audioUrl={value} setAudioUrl={onChange} disabled={disabled || readonly} />
        </FormControl>
    )
}

export default AudioRecorderWidget;
