import { Edge, Node } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import NodeJSX from "src/components/node";
import { NodeType, FlowRead, FlowsApi } from "typescript-axios";
import "./index.css";

import axios, { getAxiosParams } from "src/lib/axios.config";
import {
  Box,
  useDisclosure,
  Flex,
  Button,
  Collapse,
  VStack,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";
import { ObjectFieldTemplateProps } from "@rjsf/utils";
import { MdArrowUpward, MdArrowDownward } from "react-icons/md";

import Dagre from "@dagrejs/dagre";
import RJSFUiGrid from "./components/rjsf-ui-grid";


const FLOWS_API = new FlowsApi(getAxiosParams(), undefined, axios);

export const onCreateSheet = (
  title,
  processOauthUrl
): Promise<string | null> => {
  const redirectUrl = window.location.origin + "/auth/scopes";
  localStorage.removeItem("scopes");
  return FLOWS_API.createGsheetApiV1FlowsSheetsPost(title, redirectUrl).then(
    (response) => {
      const data = response.data;
      if (data.success && data.spreadsheet) {
        return data.spreadsheet.spreadsheet_id;
      } else if (data.url) {
        localStorage.setItem("scopes", data.scopes);
        processOauthUrl(data.url);
      }
      return null;
    }
  );
};

interface createCustomNodesArgs {
  nodeTypes: NodeType[];
  flowId: string | null;
  setFlow: ((flow: FlowRead) => void) | null;
  deleteNode: ((nodeId: string) => Promise<void>) | null;
  isDemo: boolean;
  onIconClick?: ((nodeId: string) => void) | null;
}

interface getCustomNodeArgs {
  nodeType: NodeType;
  onDelete: (nodeId: string) => void;
  onEdit: (nodeId: string, kwargs: object) => void;
  isDemo: boolean;
  onIconClick?: (nodeId: string) => void | null;
}

const getCustomNode = ({
  flowId,
  nodeType,
  onDelete,
  onEdit,
  onUngroup,
  onGetSubflowClick,
  isDemo,
  onIconClick,
  defaultIsExpanded = true,
}: getCustomNodeArgs) => {
  return ({ data, selected }) => {
    const { id: nodeId, overrides, is_trigger: isTrigger } = data;

    const hideRun = nodeType.init?.["hide-run"];
    const showRun = !isTrigger && !hideRun;
    let updateSchema = data.update_schema ?? nodeType.update;
    let outputSchema = data.output_schema ?? nodeType.outputs;
    let inputSchema = data.input_schema ?? nodeType.inputs;

    if (!showRun && inputSchema.properties) {
      delete inputSchema.properties.run;
    }

    return (
      <NodeJSX
        style={
          selected
            ? {
              boxShadow: "0 0 20px #007bffbb",
              outlineOffset: "4px",
              transition: "outline-offset 0.3s ease-in-out",
            }
            : {}
        }
        overrides={overrides}
        nodeId={nodeId}
        flowId={flowId}
        nodeType={nodeType}
        inputSchema={inputSchema}
        outputSchema={outputSchema}
        updateSchema={updateSchema}
        onGetSubflowClick={() => onGetSubflowClick(nodeId)}
        ungroup={() => onUngroup(nodeId)}
        deleteNode={() => onDelete(nodeId)}
        editNode={(kwargs) => onEdit(nodeId, kwargs)}
        data={data}
        isDemo={isDemo}
        onIconClick={onIconClick}
        defaultIsExpanded={defaultIsExpanded}
      />
    );
  };
};

export const createCustomNodes = ({
  nodeTypes,
  flowId,
  setFlow = null,
  deleteNode = null,
  editNodeWithId = null,
  onGetSubflowClick = null,
  isDemo = false,
  defaultIsExpanded = true,
  onIconClick = null,
}: createCustomNodesArgs) => {
  if (!isDemo) {
    if (!flowId) {
      throw Error("Flow ID is required");
    }
    if (!setFlow) {
      throw Error("setFlow function is required");
    }
    if (!deleteNode) {
      throw Error("deleteNode function is required");
    }
  }

  const onUngroup = (nodeId) => {
    return FLOWS_API.ungroupSubflowApiV1FlowsFlowIdSubflowSubflowIdPost(
      flowId,
      nodeId
    ).then((r) => {
      setFlow(r.data);
    });
  };

  const customNodes = {};
  // customNodes.Subflow = ({ data, selected }) => {
  //   const { id: nodeId, ...rest } = data;
  //   return (
  //     <Subflow
  //       nodeId={nodeId}
  //       data={rest}
  //       style={
  //         selected
  //           ? {
  //               boxShadow: "0 0 20px #007bffbb",
  //               outlineOffset: "4px",
  //               transition: "outline-offset 0.3s ease-in-out",
  //             }
  //           : {}
  //       }
  //       nodeKlsToNodeTypes={nodeKlsToNodeTypes}
  //     />
  //   );
  // };
  nodeTypes.forEach((nodeType) => {
    customNodes[nodeType.kls] = getCustomNode({
      flowId,
      nodeType,
      isDemo,
      onEdit: editNodeWithId,
      onDelete: deleteNode,
      onGetSubflowClick,
      onUngroup,
      onIconClick,
      defaultIsExpanded,
    });
  });

  return customNodes;
};

interface getEdgesAndNodesFromJsonOptions {
  animated?: boolean;
  withInitalDims?: boolean;
}

export const getEdgesAndNodesFromJson = (
  json: object,
  options: getEdgesAndNodesFromJsonOptions = {
    animated: false,
    withInitalDims: true,
  },
): [Edge[], Node[]] => {
  const edges: Edge[] = [];
  const runsConnected = {};
  json.links?.forEach(({ src_handle, tgt_handle, source, target, label }) => {
    const isRun = tgt_handle === "run";
    if (isRun) {
      runsConnected[target] = true;
    }
    const id = `${source}_${src_handle}_${target}_${tgt_handle}`;
    edges.push({
      source,
      target,
      id,
      sourceHandle: src_handle,
      targetHandle: tgt_handle,
      animated: options.animated,
      type: "ButtonEdge",
      // type: edgeStyle ?? "default",
      style: { strokeWidth: 2 },
      _label: label,

    });
  });
  const nodes: Node[] = [];
  json.nodes.forEach(({ id: node }) => {
    // console.debug({
    //   nodeId: node._node_id.substr(3, 5),
    //   input_schema: node.input_schema,
    // });
    const data = Object.entries(node).reduce((acc, [key, val]) => {
      if (
        {
          pos_x: true,
          pos_y: true,
          _node_id: true,
          kls: true,
        }[key]
      ) {
        return acc;
      }
      acc[key] = val;
      return acc;
    }, {});
    console.debug(node.pos_x, node.pos_y);
    const frontEndNode = {
      id: node._node_id,
      type: node.kls,
      data: {
        ...data,
        id: node._node_id,
        isRunConnected: runsConnected[node._node_id],
        pos_x: node.pos_x,
        pos_y: node.pos_y,
      },
      // set dimensions for SSR
      initialWidth: options.withInitalDims ? 160 : 'undefined',
      initialHeight: options.withInitalDims ? 50 : 'undefined',
      position: { x: node.pos_x, y: node.pos_y }, // required
    };
    nodes.push(frontEndNode);
  });
  return [edges, nodes];
};

function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  const { isOpen, onToggle } = useDisclosure();
  const { properties, uiSchema } = props;
  return (
    Object.keys(properties || {}).length > 0 && (
      <Box>
        <Flex justifyContent="end">
          <Button
            variant="ghost"
            size="sm"
            rightIcon={isOpen ? <MdArrowUpward /> : <MdArrowDownward />}
            onClick={onToggle}
          >
            Show Advanced
          </Button>
        </Flex>
        <Box as={Collapse} in={isOpen} animateOpacity>
          <RJSFUiGrid properties={props.properties} uiSchema={uiSchema} />
        </Box>
      </Box>
    )
  );
}

export const getUiSchemaForTemplate = (someSchema) => {
  const uiSchema = {
    "ui:options": { label: false },
    pos_x: { "ui:widget": "hidden" },
    pos_y: { "ui:widget": "hidden" },
    src_handles: { "ui:widget": "hidden" },
    tgt_handles: { "ui:widget": "hidden" },
  };
  for (const [nodeId, nodeProps] of Object.entries(someSchema)) {
    for (const [key, value] of Object.entries(nodeProps.properties)) {
      if (value.uiSchema) {
        uiSchema[nodeId] = {
          ...(uiSchema[nodeId] || {}),
          [key]: value.uiSchema,
        };
      }
    }
  }
  return uiSchema;
};

export const getSchemaUiSchema = (someSchema) => {
  const { base: uiSchemaBase, advanced: uiSchemaAdvanced, ...rest } = (someSchema?.uiSchema || {})
  const uiSchema = {
    base: {
      "ui:options": { label: false },
      pos_x: { "ui:widget": "hidden" },
      pos_y: { "ui:widget": "hidden" },
      src_handles: { "ui:widget": "hidden" },
      tgt_handles: { "ui:widget": "hidden" },
      ...(uiSchemaBase || {}),
    },
    advanced: {
      // "ui:options": { label: false },
      // "ui:widget": "hidden",
      "ui:ObjectFieldTemplate": ObjectFieldTemplate,
      // 'ui:ArrayFieldTemplate': ArrayFieldTemplate,
      ...(uiSchemaAdvanced || {}),
    },
    ...(rest || {}),
  };
  const schema = {
    type: "object",
    properties: {
      base: {
        type: "object",
        properties: {},
        required: [],
      },
      advanced: {
        type: "object",
        properties: {},
        required: [],
      },
    },
  };

  const required = someSchema.required ?? [];
  for (const [key, value] of Object.entries(someSchema.properties)) {
    const attrType = value.advanced ? "advanced" : "base";
    const attrSchema = schema.properties[attrType];
    attrSchema.properties[key] = value;
    if (required.includes(key)) {
      attrSchema.required.push(key);
    }
    if (value.uiSchema) {
      uiSchema[attrType][key] = value.uiSchema;
    }
  }
  return { schema, uiSchema };
};



export const getLayoutedElements = (nodes, edges) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: "TB" });
  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) =>
    g.setNode(node.id, {
      ...node,
      width: node.measured?.width ?? (node.width ?? 0),
      height: node.measured?.height ?? (node.height ?? 0),
    })
  );

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const position = g.node(node.id);
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      const x = position.x - (node.measured?.width ?? (node.width ?? 0)) / 2;
      const y = position.y - (node.measured?.height ?? (node.height ?? 0)) / 2;

      return { ...node, position: { x, y } };
    }),
    edges,
  };
};
