
const getErrorMessagesFromPropertyString = (propertyString, data) => {
    const keys = propertyString.split(".");
    let value = data;
    for (const key of keys) {
        // if starts with digit
        if (!key) {
            continue;
        }
        if (/^\d/.test(key)) {
            value = value.items;
        } else {
            value = value[key];
        }
        if (!value) {
            return null;
        }
    }
    return value.errorMessages;
};

export const transformErrors = (errors, uiSchema) => {
    return errors.map((error) => {
        const { property } = error;
        const customErrors = getErrorMessagesFromPropertyString(property, uiSchema);
        if (customErrors && customErrors[error.name]) {
            error.message = customErrors[error.name];
            error.stack = customErrors[error.name];
        }
        return error;
    });
};
